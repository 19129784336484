<template>
  <div>
    <h3>Credit cards</h3>
    <br>
    <v-data-table
      :headers="headers"
      :items="cardsCopy"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:top>
        <br>
        <v-toolbar
          flat
        >
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="resetValues"
                :disabled="FIELDS_DISABLED"
              >
                Add New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-combobox
                        v-model="editedItem.ownership"
                        :items="ownershipOptions"
                        label="Ownership"
                        dense
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        type="text"
                        v-model="editedItem.creditor"
                        placeholder="Creditor"
                        label="Creditor"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        type="text"
                        v-model="creditCardsBalance"
                        @focus="balanceNumberType"
                        @blur="balanceTextType"
                        placeholder="Balance, $"
                        label="Balance, $"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        type="text"
                        v-model="creditCardsLimit"
                        @focus="limitNumberType"
                        @blur="limitTextType"
                        placeholder="Limit, $"
                        label="Limit, $"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        type="text"
                        v-model="creditCardsRepayment"
                        @focus="repaymentNumberType"
                        @blur="repaymentTextType"
                        placeholder="Monthly repayments, $"
                        label="Monthly repayments, $"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        dense
                        v-model="editedItem.debt"
                        :items="estateAssetsSectionData.securityOptions"
                        label="Consolidate debt"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveData"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Are you sure you want to delete this item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <br>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import { estateAssetsSectionData } from '../dataOptions';

import FeedOptionsMixin from '@/mixins/FeedOptionsMixin';

export default {
  name: 'CreditCardsComponent',

  mixins: [FeedOptionsMixin],

  props: {
    creditCards: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      estateAssetsSectionData,

      creditCardsData: [],
      cardsCopy: [],

      creditCardsBalance: '',
      creditCardsLimit: '',
      creditCardsRepayment: '',

      feedMainOptionsKey: {
        ownership: 'mainOwnershipOptions',
      },

      mainOwnershipOptions: ['Joint', 'Guaranator'],

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Ownership', value: 'ownership', sortable: false },
        { text: 'Creditor', value: 'creditor', sortable: false },
        { text: 'Balance, $', value: 'balance', sortable: false },
        { text: 'Limit, $', value: 'limit', sortable: false },
        { text: 'Monthly repayments, $', value: 'repayment', sortable: false },
        { text: 'Consolidate debt', value: 'debt', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        ownership: '',
        creditor: '',
        balance: '',
        limit: '',
        repayment: '',
        debt: '',
      },
      defaultItem: {
        ownership: '',
        creditor: '',
        balance: '',
        limit: '',
        repayment: '',
        debt: '',
      },
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),

    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
  },

  methods: {
    resetValues() {
      this.creditCardsBalance = '';
      this.creditCardsLimit = '';
      this.creditCardsRepayment = '';
    },

    textFieldsFormat() {
      this.cardsCopy.forEach((card, index) => {
        this.creditCardsData[index].balance = this.$options.filters.textFormatter(card.balance);
        this.creditCardsData[index].limit = this.$options.filters.textFormatter(card.limit);
        this.creditCardsData[index].repayment = this.$options.filters.textFormatter(card.repayment);
      });
    },

    numberFieldsFormat() {
      this.creditCardsData.forEach((card, index) => {
        this.cardsCopy[index].balance = this.$options.filters.integerFormatter(card.balance);
        this.cardsCopy[index].limit = this.$options.filters.integerFormatter(card.limit);
        this.cardsCopy[index].repayment = this.$options.filters.integerFormatter(card.repayment);
      });
    },

    editItem(item) {
      this.editedIndex = this.cardsCopy.indexOf(item);
      this.editedItem = { ...item };
      this.creditCardsBalance = this.$options.filters.integerFormatter(this.editedItem.balance);
      this.creditCardsLimit = this.$options.filters.integerFormatter(this.editedItem.limit);
      this.creditCardsRepayment = this.$options.filters.integerFormatter(this.editedItem.repayment);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.cardsCopy.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.cardsCopy.splice(this.editedIndex, 1);
      this.creditCardsData.splice(this.editedIndex, 1);
      this.$emit('changeCreditCards', this.creditCardsData);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    saveData() {
      if (this.editedIndex > -1) {
        Object.assign(this.cardsCopy[this.editedIndex], this.editedItem);
      } else {
        this.cardsCopy.push(this.editedItem);
      }

      this.creditCardsData = clone(this.cardsCopy);
      this.textFieldsFormat();
      this.numberFieldsFormat();

      this.$emit('changeCreditCards', this.creditCardsData);

      this.close();
    },

    balanceTextType(event) {
      const target = event?.target;
      target.type = 'text';
      this.creditCardsBalance = this.$options.filters.integerFormatter(this.creditCardsBalance);
      this.editedItem.balance = this.$options.filters.textFormatter(this.creditCardsBalance);
    },

    balanceNumberType(event) {
      const target = event?.target;
      target.type = 'number';
      this.creditCardsBalance = this.$options.filters.textFormatter(this.creditCardsBalance);
    },

    limitTextType(event) {
      const target = event?.target;
      target.type = 'text';
      this.creditCardsLimit = this.$options.filters.integerFormatter(this.creditCardsLimit);
      this.editedItem.limit = this.$options.filters.textFormatter(this.creditCardsLimit);
    },

    limitNumberType(event) {
      const target = event?.target;
      target.type = 'number';
      this.creditCardsLimit = this.$options.filters.textFormatter(this.creditCardsLimit);
    },

    repaymentTextType(event) {
      const target = event?.target;
      target.type = 'text';
      this.creditCardsRepayment = this.$options.filters.integerFormatter(this.creditCardsRepayment);
      this.editedItem.repayment = this.$options.filters.textFormatter(this.creditCardsRepayment);
    },

    repaymentNumberType(event) {
      const target = event?.target;
      target.type = 'number';
      this.creditCardsRepayment = this.$options.filters.textFormatter(this.creditCardsRepayment);
    },
  },

  created() {
    this.creditCardsData = clone(this.creditCards);
    this.cardsCopy = clone(this.creditCardsData);

    this.numberFieldsFormat();
  },
};
</script>
