var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-5"
  }, [_c('v-radio-group', {
    attrs: {
      "row": true
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_c('h3', [_vm._v(" Are you or any joint applicant acting as a guarantor for any other person(s)? ")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.guarantorData.guarantor,
      callback: function callback($$v) {
        _vm.$set(_vm.guarantorData, "guarantor", $$v);
      },
      expression: "guarantorData.guarantor"
    }
  }, _vm._l(_vm.guarantorOptions, function (guarantorItem, idx) {
    return _c('v-radio', {
      key: idx,
      attrs: {
        "value": guarantorItem.value,
        "disabled": _vm.FIELDS_DISABLED
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('div', [_vm._v(_vm._s(guarantorItem.text))])];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1), _vm.guarantorData.guarantor ? _c('div', {
    staticClass: "mb-5"
  }, [_c('v-expand-transition', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "name": "input-7-4",
      "label": "Please provide details",
      "placeholder": "Please provide details",
      "rows": "4",
      "auto-grow": "",
      "disabled": _vm.FIELDS_DISABLED
    },
    model: {
      value: _vm.guarantorData.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.guarantorData, "comment", $$v);
      },
      expression: "guarantorData.comment"
    }
  })], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }