var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', [_vm._v("Other liabilities")]), _c('br'), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.liabiliesCopy,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('br'), _c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-dialog', {
          attrs: {
            "max-width": "500px"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mb-2",
                attrs: {
                  "color": "primary",
                  "disabled": _vm.FIELDS_DISABLED
                },
                on: {
                  "click": _vm.resetValues
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" Add New ")])];
            }
          }]),
          model: {
            value: _vm.dialog,
            callback: function callback($$v) {
              _vm.dialog = $$v;
            },
            expression: "dialog"
          }
        }, [_c('v-card', [_c('v-card-title', [_c('span', {
          staticClass: "text-h5"
        }, [_vm._v(_vm._s(_vm.formTitle))])]), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('v-select', {
          attrs: {
            "dense": "",
            "items": _vm.typeOptions,
            "label": "Type",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.editedItem.type,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "type", $$v);
            },
            expression: "editedItem.type"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('v-combobox', {
          attrs: {
            "items": _vm.ownershipOptions,
            "label": "Ownership",
            "dense": "",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.editedItem.ownership,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "ownership", $$v);
            },
            expression: "editedItem.ownership"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('v-text-field', {
          attrs: {
            "dense": "",
            "type": "text",
            "placeholder": "Creditor",
            "label": "Creditor",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.editedItem.creditor,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "creditor", $$v);
            },
            expression: "editedItem.creditor"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('v-text-field', {
          attrs: {
            "dense": "",
            "type": "text",
            "placeholder": "Balance, $",
            "label": "Balance, $",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "focus": _vm.balanceNumberType,
            "blur": _vm.balanceTextType
          },
          model: {
            value: _vm.liabiliesBalance,
            callback: function callback($$v) {
              _vm.liabiliesBalance = $$v;
            },
            expression: "liabiliesBalance"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('v-text-field', {
          attrs: {
            "dense": "",
            "type": "text",
            "placeholder": "Repayment, $",
            "label": "Repayment, $",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "focus": _vm.repaymentNumberType,
            "blur": _vm.repaymentTextType
          },
          model: {
            value: _vm.liabiliesRepayment,
            callback: function callback($$v) {
              _vm.liabiliesRepayment = $$v;
            },
            expression: "liabiliesRepayment"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('v-select', {
          attrs: {
            "dense": "",
            "items": _vm.repaymentPeriodOptions,
            "label": "Repayment period",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.editedItem.repayment_period,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "repayment_period", $$v);
            },
            expression: "editedItem.repayment_period"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('v-menu', {
          ref: "terms",
          attrs: {
            "close-on-content-click": false,
            "transition": "scale-transition",
            "offset-y": "",
            "min-width": "auto",
            "disabled": _vm.FIELDS_DISABLED
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-text-field', _vm._g(_vm._b({
                directives: [{
                  name: "mask",
                  rawName: "v-mask",
                  value: '####-##-##',
                  expression: "'####-##-##'"
                }],
                attrs: {
                  "dense": "",
                  "rules": _vm.dateRules,
                  "label": "Loan Term Ends",
                  "prepend-icon": "mdi-calendar",
                  "disabled": _vm.FIELDS_DISABLED
                },
                on: {
                  "blur": _vm.dateUpdate
                },
                model: {
                  value: _vm.dateData,
                  callback: function callback($$v) {
                    _vm.dateData = $$v;
                  },
                  expression: "dateData"
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }]),
          model: {
            value: _vm.termsDate,
            callback: function callback($$v) {
              _vm.termsDate = $$v;
            },
            expression: "termsDate"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "active-picker": _vm.activePicker
          },
          on: {
            "update:activePicker": function updateActivePicker($event) {
              _vm.activePicker = $event;
            },
            "update:active-picker": function updateActivePicker($event) {
              _vm.activePicker = $event;
            },
            "change": function change($event) {
              var _vm2;

              return (_vm2 = _vm).saveDate.apply(_vm2, ['terms'].concat(Array.prototype.slice.call(arguments)));
            }
          },
          model: {
            value: _vm.editedItem.terms,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "terms", $$v);
            },
            expression: "editedItem.terms"
          }
        })], 1)], 1), _c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('v-select', {
          attrs: {
            "dense": "",
            "items": _vm.estateAssetsSectionData.securityOptions,
            "label": "Consolidate debt",
            "disabled": _vm.FIELDS_DISABLED
          },
          model: {
            value: _vm.editedItem.debt,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "debt", $$v);
            },
            expression: "editedItem.debt"
          }
        })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _vm.saveData
          }
        }, [_vm._v(" Save ")])], 1)], 1)], 1), _c('v-dialog', {
          attrs: {
            "max-width": "500px"
          },
          model: {
            value: _vm.dialogDelete,
            callback: function callback($$v) {
              _vm.dialogDelete = $$v;
            },
            expression: "dialogDelete"
          }
        }, [_c('v-card', [_c('v-card-title', {
          staticClass: "text-h5"
        }, [_vm._v(" Are you sure you want to delete this item? ")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _vm.closeDelete
          }
        }, [_vm._v("Cancel")]), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": _vm.deleteItemConfirm
          }
        }, [_vm._v("OK")]), _c('v-spacer')], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(item);
            }
          }
        }, [_vm._v(" mdi-pencil ")]), _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }], null, true)
  }), _c('br')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }