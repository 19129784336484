var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([_vm.sectionReady ? {
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('MortgagesComponent', {
          attrs: {
            "mortgages": _vm.liabilities.mortgages
          },
          on: {
            "changeMortgages": _vm.changeMortgages
          }
        }), _c('CreditCardsComponent', {
          attrs: {
            "creditCards": _vm.liabilities.credit_cards
          },
          on: {
            "changeCreditCards": _vm.changeCreditCards
          }
        }), _c('LiabilitiesComponent', {
          attrs: {
            "liabilities": _vm.liabilities.items
          },
          on: {
            "changeLiabilities": _vm.changeLiabilities
          }
        }), _c('GuarantorComponent', {
          attrs: {
            "guarantor": _vm.liabilities.guarantor,
            "guarantorComment": _vm.liabilities.guarantor_comment
          },
          on: {
            "changeGuarantor": _vm.changeGuarantor
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }