<template>
  <div>
    <h3>Mortgages</h3>
    <br>
    <v-data-table
      :headers="headers"
      :items="mortgagesCopy"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:top>
        <br>
        <v-toolbar
          flat
        >
          <v-dialog
            v-model="dialog"
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="resetValues"
                :disabled="FIELDS_DISABLED"
              >
                Add New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-combobox
                        v-model="editedItem.ownership"
                        :items="ownershipOptions"
                        label="Ownership"
                        dense
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-combobox
                        dense
                        v-model="editedItem.creditor"
                        :items="creditorList"
                        label="Creditor"
                        placeholder="Creditor"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        type="text"
                        v-model="mortgagesBalance"
                        @focus="balanceNumberType"
                        @blur="balanceTextType"
                        placeholder="Balance, $"
                        label="Balance, $"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        type="text"
                        v-model="mortgagesRepayment"
                        @focus="repaymentNumberType"
                        @blur="repaymentTextType"
                        placeholder="Repayment, $"
                        label="Repayment, $"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        dense
                        v-model="editedItem.repayment_period"
                        :items="repaymentPeriodOptions"
                        label="Repayment period"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-menu
                        ref="terms"
                        v-model="termsDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :disabled="FIELDS_DISABLED"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            dense
                            v-mask="'####-##-##'"
                            v-model="dateData"
                            :rules="dateRules"
                            label="Loan Term Ends"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="FIELDS_DISABLED"
                            @blur="dateUpdate"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.terms"
                          :active-picker.sync="activePicker"
                          @change="saveDate('terms', ...arguments)"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        dense
                        v-model="editedItem.refianance"
                        :items="estateAssetsSectionData.mortgagesSecurityOptions"
                        label="Refinance/Security to bank"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveData"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Are you sure you want to delete this item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <br>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import { estateAssetsSectionData } from '../dataOptions';

import SummaryMixins from '@/mixins/SummaryMixin';
import FeedOptionsMixin from '@/mixins/FeedOptionsMixin';

import getSressedMonthRepayment from '@/mixins/mortgagesStressRepayment';

import { dateRequired, dateValidation } from '@/utils/validation';

export default {
  name: 'MortgagesComponent',

  mixins: [SummaryMixins, FeedOptionsMixin],

  props: {
    mortgages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dateData: null,

      estateAssetsSectionData,

      termsDate: null,
      activePicker: null,

      mortgagesData: [],
      mortgagesCopy: [],

      mortgagesBalance: '',
      mortgagesRepayment: '',

      feedMainOptionsKey: {
        ownership: 'mainOwnershipOptions',
      },

      mainOwnershipOptions: ['Joint', 'Guarantor'],

      repaymentPeriodOptions: ['Week', 'Fortnight', 'Month', 'Year'],

      creditorList: ['ANZ', 'ASB', 'BNZ', 'Co-operative Bank', 'Resimac', 'SBS', 'Select', 'Unity', 'Westpac'],

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Ownership', value: 'ownership', sortable: false },
        { text: 'Creditor', value: 'creditor', sortable: false },
        { text: 'Balance, $', value: 'balance', sortable: false },
        { text: 'Repayment, $', value: 'repayment', sortable: false },
        { text: 'Repayment period', value: 'repayment_period', sortable: false },
        { text: 'Loan Term Ends', value: 'terms', sortable: false },
        { text: 'Refinance/Security to bank', value: 'refianance', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        ownership: '',
        creditor: '',
        balance: '',
        repayment: '',
        repayment_period: '',
        terms: '',
        refianance: '',
        stressed: '',
      },
      defaultItem: {
        ownership: '',
        creditor: '',
        balance: '',
        repayment: '',
        repayment_period: '',
        terms: '',
        refianance: '',
        stressed: '',
      },

      dateRules: [
        dateRequired('Loan Term Ends'),
        dateValidation('Loan Terms Ends'),
      ],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),

    mortgagesComputed() {
      return this.APPLICATION?.data?.liabilities?.mortgages;
    },

    LFDTerm() {
      const term = parseInt(this.APPLICATION?.data?.funding_details?.debt?.[0]?.term, 10);

      const finalDate = new Date();

      finalDate.setFullYear(finalDate.getFullYear() + term);

      return finalDate;
    },

    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
  },

  methods: {
    async dateUpdate(event) {
      const value = await event.target.value;

      const isValueValid = Boolean(value.length === 10);

      if (isValueValid) {
        this.editedItem.terms = this.$options.filters.dateISOFormatter(value);
      }
    },

    saveDate(ref, date) {
      this.$refs[ref]?.save(date);
    },

    resetValues() {
      this.mortgagesBalance = '';
      this.mortgagesRepayment = '';
    },

    textFieldsFormat() {
      this.mortgagesCopy.forEach((card, index) => {
        this.mortgagesData[index].balance = this.$options.filters.textFormatter(card.balance);
        this.mortgagesData[index].repayment = this.$options.filters.textFormatter(card.repayment);
        this.mortgagesData[index].stressed = this.$options.filters.textFormatter(card.stressed);
      });
    },

    numberFieldsFormat() {
      this.mortgagesData.forEach((card, index) => {
        this.mortgagesCopy[index].balance = this.$options.filters.integerFormatter(card.balance);
        this.mortgagesCopy[index].repayment = this.$options.filters
          .integerFormatter(card.repayment);
        this.mortgagesCopy[index].stressed = this.$options.filters.integerFormatter(card.stressed);
      });
    },

    editItem(item) {
      this.editedIndex = this.mortgagesCopy.indexOf(item);
      this.editedItem = { ...item };
      this.mortgagesBalance = this.$options.filters.integerFormatter(this.editedItem.balance);
      this.mortgagesRepayment = this.$options.filters.integerFormatter(this.editedItem.repayment);

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.mortgagesCopy.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.mortgagesCopy.splice(this.editedIndex, 1);
      this.mortgagesData.splice(this.editedIndex, 1);
      this.$emit('changeMortgages', this.mortgagesData);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    saveData() {
      if (this.editedIndex > -1) {
        this.editItem.term = this.mortgagesComputed[this.editedIndex].term;
        Object.assign(this.mortgagesCopy[this.editedIndex], this.editedItem);
      } else {
        const now = new Date();
        const id = Date.parse(now);
        const term = this.APPLICATION?.data?.funding_details?.debt?.[0]?.term;

        const newData = { ...this.editedItem, id, term };

        this.mortgagesCopy.push(newData);
      }

      this.mortgagesCopy = this.stressedMonthRepayment();

      this.mortgagesData = clone(this.mortgagesCopy);
      this.textFieldsFormat();
      this.numberFieldsFormat();

      this.$emit('changeMortgages', this.mortgagesData);

      this.close();
    },

    balanceTextType(event) {
      const target = event?.target;
      target.type = 'text';
      this.mortgagesBalance = this.$options.filters.integerFormatter(this.mortgagesBalance);
      this.editedItem.balance = this.$options.filters.textFormatter(this.mortgagesBalance);
    },

    balanceNumberType(event) {
      const target = event?.target;
      target.type = 'number';
      this.mortgagesBalance = this.$options.filters.textFormatter(this.mortgagesBalance);
    },

    repaymentTextType(event) {
      const target = event?.target;
      target.type = 'text';
      this.mortgagesRepayment = this.$options.filters.integerFormatter(this.mortgagesRepayment);
      this.editedItem.repayment = this.$options.filters.textFormatter(this.mortgagesRepayment);
    },

    repaymentNumberType(event) {
      const target = event?.target;
      target.type = 'number';
      this.mortgagesRepayment = this.$options.filters.textFormatter(this.mortgagesRepayment);
    },

    stressedMonthRepayment() {
      const stressRate = this.APPLICATION.data.lender.stress_rate;

      const stressedMortgages = this.mortgagesCopy.map((item) => {
        const isRefinance = Boolean(item.refianance === 'Refinance');

        const term = isRefinance ? this.LFDTerm : item.terms;

        const value = this.$options.filters.textFormatter(item.balance);

        const stressed = Math.round(getSressedMonthRepayment(term, value, stressRate));

        const newItem = { ...item };

        const stressedIsNaN = Number.isNaN(stressed);

        newItem.stressed = stressedIsNaN ? '0' : `${stressed}`;

        return newItem;
      });

      return stressedMortgages;
    },
  },

  created() {
    this.mortgagesData = clone(this.mortgages);
    this.mortgagesCopy = clone(this.mortgagesData);

    this.numberFieldsFormat();
  },

  watch: {
    'editedItem.terms': {
      handler(val) {
        this.dateData = val;
      },
    },

    termsDate(val) {
      if (val) {
        const activePickerEdit = () => {
          this.activePicker = 'YEAR';
        };

        setTimeout(activePickerEdit);
      }
    },
  },
};
</script>
