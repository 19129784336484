<template>
  <div>
    <div class="mb-5">
      <v-radio-group
        v-model="guarantorData.guarantor"
        :row="true"
      >
        <template #label>
          <div>
            <h3>
              Are you or any joint applicant acting as a guarantor for any other person(s)?
            </h3>
          </div>
        </template>
        <v-radio
          v-for="(guarantorItem, idx) in guarantorOptions"
          :value="guarantorItem.value"
          :key="idx"
          :disabled="FIELDS_DISABLED"
        >
          <template #label>
            <div>{{ guarantorItem.text }}</div>
          </template>
        </v-radio>
      </v-radio-group>
    </div>

    <div class="mb-5" v-if="guarantorData.guarantor">
      <v-expand-transition>
        <v-textarea
          outlined
          name="input-7-4"
          label="Please provide details"
          placeholder="Please provide details"
          v-model="guarantorData.comment"
          rows="4"
          auto-grow
          :disabled="FIELDS_DISABLED"
        ></v-textarea>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GuarantorComponent',

  props: {
    guarantor: {
      type: Boolean,
      default: false,
    },

    guarantorComment: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      guarantorData: {
        guarantor: false,
        comment: '',
      },

      guarantorOptions: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),
  },

  created() {
    this.guarantorData = {
      guarantor: this.guarantor,
      comment: this.guarantorComment,
    };
  },

  watch: {
    guarantorData: {
      handler(value) {
        this.$emit('changeGuarantor', value);
      },
      deep: true,
    },
  },
};
</script>
