<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section v-if="sectionReady">
      <v-card-text class="mt-4">
        <MortgagesComponent
          :mortgages="liabilities.mortgages"
          @changeMortgages="changeMortgages"
        >
        </MortgagesComponent>

        <CreditCardsComponent
          :creditCards="liabilities.credit_cards"
          @changeCreditCards="changeCreditCards"
        >
        </CreditCardsComponent>

        <LiabilitiesComponent
          :liabilities="liabilities.items"
          @changeLiabilities="changeLiabilities"
        >
        </LiabilitiesComponent>

        <GuarantorComponent
          :guarantor="liabilities.guarantor"
          :guarantorComment="liabilities.guarantor_comment"
          @changeGuarantor="changeGuarantor"
        >
        </GuarantorComponent>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';
import MortgagesComponent from '@/components/ApplicationSections/Components/MortgagesComponent.vue';
import LiabilitiesComponent from '@/components/ApplicationSections/Components/LiabilitiesComponent.vue';
import GuarantorComponent from '@/components/ApplicationSections/Components/GuarantorComponent.vue';
import CreditCardsComponent from '@/components/ApplicationSections/Components/CreditCardsComponent.vue';

export default {
  name: 'liabilitiesSection',

  components: {
    BaseSection,
    MortgagesComponent,
    LiabilitiesComponent,
    GuarantorComponent,
    CreditCardsComponent,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sectionReady: false,

      isSectionVisible: false,

      initialLiabilities: {},

      liabilities: {
        items: [],
        guarantor: false,
        guarantor_comment: '',
        credit_cards: [],
        mortgages: [],
      },
    };
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialLiabilities = await clone(this.existedData);
    this.liabilities = await clone(this.existedData);

    this.sectionReady = true;

    this.$emit('componentReady');
  },

  methods: {
    changeLiabilities(data) {
      this.liabilities.items = data;
    },

    changeMortgages(data) {
      this.liabilities.mortgages = data;
    },

    changeCreditCards(data) {
      this.liabilities.credit_cards = data;
    },

    changeGuarantor(data) {
      this.liabilities.guarantor = data.guarantor;
      this.liabilities.guarantor_comment = data.comment;
    },
  },
};
</script>
