<template>
  <div>
    <h3>Other liabilities</h3>
    <br>
    <v-data-table
      :headers="headers"
      :items="liabiliesCopy"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:top>
        <br>
        <v-toolbar
          flat
        >
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="resetValues"
                :disabled="FIELDS_DISABLED"
              >
                Add New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        dense
                        v-model="editedItem.type"
                        :items="typeOptions"
                        label="Type"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-combobox
                        v-model="editedItem.ownership"
                        :items="ownershipOptions"
                        label="Ownership"
                        dense
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        type="text"
                        v-model="editedItem.creditor"
                        placeholder="Creditor"
                        label="Creditor"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        type="text"
                        v-model="liabiliesBalance"
                        @focus="balanceNumberType"
                        @blur="balanceTextType"
                        placeholder="Balance, $"
                        label="Balance, $"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        dense
                        type="text"
                        v-model="liabiliesRepayment"
                        @focus="repaymentNumberType"
                        @blur="repaymentTextType"
                        placeholder="Repayment, $"
                        label="Repayment, $"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        dense
                        v-model="editedItem.repayment_period"
                        :items="repaymentPeriodOptions"
                        label="Repayment period"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-menu
                        ref="terms"
                        v-model="termsDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :disabled="FIELDS_DISABLED"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            dense
                            v-mask="'####-##-##'"
                            v-model="dateData"
                            :rules="dateRules"
                            label="Loan Term Ends"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="FIELDS_DISABLED"
                            @blur="dateUpdate"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.terms"
                          :active-picker.sync="activePicker"
                          @change="saveDate('terms', ...arguments)"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        dense
                        v-model="editedItem.debt"
                        :items="estateAssetsSectionData.securityOptions"
                        label="Consolidate debt"
                        :disabled="FIELDS_DISABLED"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveData"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Are you sure you want to delete this item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <br>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import FeedOptionsMixin from '@/mixins/FeedOptionsMixin';

import { estateAssetsSectionData } from '../dataOptions';

import { dateRequired, dateValidation } from '@/utils/validation';

export default {
  name: 'LiabilitiesComponent',

  mixins: [FeedOptionsMixin],

  props: {
    liabilities: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dateData: null,

      estateAssetsSectionData,

      termsDate: null,
      activePicker: null,

      liabiliesData: [],
      liabiliesCopy: [],

      liabiliesBalance: '',
      liabiliesRepayment: '',

      feedMainOptionsKey: {
        ownership: 'mainOwnershipOptions',
      },

      mainOwnershipOptions: ['Joint', 'Guaranator'],

      typeOptions: ['Mortgage', 'Personal loan', 'Student loan', 'Vehicle loan', 'Business loan', 'Government/Tax', 'Other'],
      repaymentPeriodOptions: ['Week', 'Fortnight', 'Month', 'Year'],

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Ownership', value: 'ownership', sortable: false },
        { text: 'Creditor', value: 'creditor', sortable: false },
        { text: 'Balance, $', value: 'balance', sortable: false },
        { text: 'Repayment, $', value: 'repayment_amount', sortable: false },
        { text: 'Repayment period', value: 'repayment_period', sortable: false },
        { text: 'Loan Term Ends', value: 'terms', sortable: false },
        { text: 'Consolidate debt', value: 'debt', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        type: '',
        ownership: '',
        creditor: '',
        balance: '',
        repayment_amount: '',
        repayment_period: '',
        terms: '',
        debt: '',
      },
      defaultItem: {
        type: '',
        ownership: '',
        creditor: '',
        balance: '',
        repayment_amount: '',
        repayment_period: '',
        terms: '',
        debt: '',
      },

      dateRules: [
        dateRequired('Loan Term Ends'),
        dateValidation('Loan Terms End'),
      ],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),

    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
  },

  methods: {
    async dateUpdate(event) {
      const value = await event.target.value;

      const isValueValid = Boolean(value.length === 10);

      if (isValueValid) {
        this.editedItem.terms = this.$options.filters.dateISOFormatter(value);
      }
    },

    resetValues() {
      this.liabiliesBalance = '';
      this.liabiliesRepayment = '';
    },

    saveDate(ref, date) {
      this.$refs[ref]?.save(date);
    },

    textFieldsFormat() {
      this.liabiliesCopy.forEach((liability, index) => {
        this.liabiliesData[index].balance = this.$options.filters.textFormatter(liability.balance);
        this.liabiliesData[index].repayment_amount = this.$options.filters
          .textFormatter(liability.repayment_amount);
      });
    },

    numberFieldsFormat() {
      this.liabiliesData.forEach((liability, index) => {
        this.liabiliesCopy[index].balance = this.$options.filters
          .integerFormatter(liability.balance);
        this.liabiliesCopy[index].repayment_amount = this.$options.filters
          .integerFormatter(liability.repayment_amount);
      });
    },

    editItem(item) {
      this.editedIndex = this.liabiliesCopy.indexOf(item);
      this.editedItem = { ...item };
      this.liabiliesBalance = this.$options.filters
        .integerFormatter(this.editedItem.balance);
      this.liabiliesRepayment = this.$options.filters
        .integerFormatter(this.editedItem.repayment_amount);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.liabiliesCopy.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.liabiliesCopy.splice(this.editedIndex, 1);
      this.liabiliesData.splice(this.editedIndex, 1);
      this.$emit('changeLiabilities', this.liabiliesData);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    saveData() {
      if (this.editedIndex > -1) {
        Object.assign(this.liabiliesCopy[this.editedIndex], this.editedItem);
      } else {
        this.liabiliesCopy.push(this.editedItem);
      }

      this.liabiliesData = clone(this.liabiliesCopy);
      this.textFieldsFormat();
      this.numberFieldsFormat();

      this.$emit('changeLiabilities', this.liabiliesData);

      this.close();
    },

    balanceTextType(event) {
      const target = event?.target;
      target.type = 'text';
      this.liabiliesBalance = this.$options.filters.integerFormatter(this.liabiliesBalance);
      this.editedItem.balance = this.$options.filters.textFormatter(this.liabiliesBalance);
    },

    balanceNumberType(event) {
      const target = event?.target;
      target.type = 'number';
      this.liabiliesBalance = this.$options.filters.textFormatter(this.liabiliesBalance);
    },

    repaymentTextType(event) {
      const target = event?.target;
      target.type = 'text';
      this.liabiliesRepayment = this.$options.filters.integerFormatter(this.liabiliesRepayment);
      this.editedItem.repayment_amount = this.$options.filters
        .textFormatter(this.liabiliesRepayment);
    },

    repaymentNumberType(event) {
      const target = event?.target;
      target.type = 'number';
      this.liabiliesRepayment = this.$options.filters.textFormatter(this.liabiliesRepayment);
    },
  },

  created() {
    this.liabiliesData = clone(this.liabilities);
    this.liabiliesCopy = clone(this.liabiliesData);

    this.numberFieldsFormat();
  },

  watch: {
    'editedItem.terms': {
      handler(val) {
        this.dateData = val;
      },
    },

    termsDate(val) {
      if (val) {
        const activePickerEdit = () => {
          this.activePicker = 'YEAR';
        };

        setTimeout(activePickerEdit);
      }
    },
  },
};
</script>
